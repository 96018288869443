<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>企业商户管理</el-breadcrumb-item>
        <el-breadcrumb-item>企业商户列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-input
                  placeholder="企业id"
                  v-model="form3.device_id"
                  class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-input
                  placeholder="企业名称"
                  v-model="form3.device_name"
                  class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6" class="tl mr10">
        <el-form-item size="medium">
          <el-button
                  type="primary"
                  @click="search"
                  class="general_bgc general_border cfff"
          >查询</el-button
          >
          <el-button type="primary" size="medium" @click="Add"
          >新增配置</el-button>
          <el-button class size="medium" @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-col>
    </el-form>
    <el-table
            border
            :data="tableData"
            style="width: 100%">
      <el-table-column
              type="index"
              label="企业id"
              width="55">
      </el-table-column>
      <el-table-column
              prop="device_id"
              label="企业名称"
              width="180">
      </el-table-column>
      <el-table-column
              prop="device_name"
              label="标签类型"
              width="180">
      </el-table-column>
      <el-table-column
              prop="company_name"
              label="所属企业"
              width="180">
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" class="disib blue" size="mini" @click="handleUpdate(scope.row)">修改</el-button>
          <el-button type="text" class="disib red" size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page fr disib mt20">
      <el-pagination
              background
              :page-size="10"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="currentPage"
              @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="dialogAddgsVisible"   @close="closeDialogAddgsVisible" >
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item size="medium"
                      label="设备编号id：">
          <el-input
                  placeholder="设备编号id"
                  v-model="form3.device_id"
                  clearable
                  style="width: 50%"
                  class="input-with-select"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium"
                      label="设备名称：">
          <el-input
                  placeholder="设备名称"
                  v-model="form3.device_name"
                  clearable
                  style="width: 50%"
                  class="input-with-select"
          >
          </el-input>
        </el-form-item>
        <el-form-item size="medium" label="企业名称：">
          <el-autocomplete
                  placeholder="企业名称"
                  v-model="form3.company_name"
                  :fetch-suggestions="querySearchAsync"
                  clearable
                  style="width: 50%"
                  class="input-with-select"
                  @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <span class="name">{{item.company_name}}</span>
              <span class="addr" style="font-size:12px;color:silver;float: right">{{item.credit_code}}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item size="medium">
          <el-button type="primary" size="medium" @click="submit"
          >{{handleName}}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>
<script>
  import axios from '../../../util/api'
  import { session, aa } from '../../../util/util'
  export default {
    data () {
      return {
        tableData:[],
        allCompany:[],
        form:{
          company_name:"",
          pager_offset: "0",
          pager_openset: "10",
          credit_code:"",
          signDepartment:'',
        },
        form3:{
          device_id:"",
          device_name:"",
          company_id:"",
          company_name:"",
        },
        handleName:"添加",
        title:"新增企业商户配置",
        currentPage: 1,
        allCurrentPage:1,
        total:0,
        allTotal:0,
        dialogAddgsVisible:false,
        companyId:[],
      }
    },
    mounted () {
      this.getData(this.form);
    },
    methods: {
      getData(){
        axios.get("/pc/company-device/list",this.form3).then(res=>{
          res = res.data;
          if (res){
            this.tableData = res.company_device_list;
            this.total = res.company_device_total;
          }
        });
      },
      //根据库房名称查询信息
      querySearchAsync(queryString, cb) {
        if (queryString) {
          axios.get("/pc/company/signList/2",{company_name:this.form3.company_name})
                  .then((response) => {
                    let results = response.data.company_list;
                    cb(results);
                  });
        } else {
          cb([]);
        }
      },
      search(){
        this.getData();
      },
      handleUpdate(item){
        this.form3.id = item.id;
        this.form3.device_name = item.device_name;
        this.form3.device_id = item.device_id;
        this.form3.company_name = item.company_name;
        this.form3.company_id = item.company_id;
        this.dialogAddgsVisible = true;
        this.handleName = '修改';
      },
      handleSelect(item) {
        this.form3.company_id = item.company_id;
        this.form3.company_name = item.company_name;
      },
      Add(){
        this.dialogAddgsVisible = true;
        this.form3.company_name = "";
        this.form3.company_id = "";
        this.form3.device_name = "";
        this.form3.device_id =""
        this.handleName = '添加'
      },
      handleDelete(row){
        // let { user_id } = row;
        // axios.delete("/pc/company/deleteSign",{
        //   departmentSignId:this.form.signDepartment,
        //   company_id:String(row.company_id)
        // }).then(response => {
        //   this.getData(this.form);
        // });
      },
      submit(){
        if(!this.form3.id){
          axios.post("/pc/company-device/add",this.form3).then(res=>{
            this.dialogAddgsVisible =false;
            this.form3.company_name = '';
            this.form3.device_id ='';
            this.form3.device_name = '';
            this.getData(this.form3);
          })
        }else{
          axios.post("/pc/company-device/update",this.form3).then(res=>{
            this.dialogAddgsVisible =false;
            this.form3.company_name = '';
            this.form3.device_id ='';
            this.form3.device_name = '';
            this.getData(this.form3);
          })
        }
      },
      closeDialogAddgsVisible(){
        this.dialogAddgsVisible = false;
      },
      currentChange(page) {
        this.currentPage = page;
        this.form.pager_offset = String((page - 1) * 10);
        this.getData(this.form);
      },
      allCurrentChange(page) {
        this.allCurrentPage = page;
        this.form2.pager_offset = String((page - 1) * 10);
        this.getAllData(this.form2);
      },
    }
  }
</script>
<style lang="scss" scoped>
  .shopList {
    overflow-x: hidden;
    .add {
      display: flex;
      top: 0px;
      left: 30px;
      justify-content: space-between;
      align-items: center;
    }
    .active {
      background: #2ec68a;
      border-color: #2ec68a;
      color: #ffffff;
    }
    .list_tab {
      padding: 10px;
      background-color: #f5f7fa;
      .list_tag {
        right: -10px;
        padding: 2px 6px;
        border-radius: 10px;
        top: -10px;
      }
    }
    /deep/ .el-input-group__append .el-button--primary .el-icon-search {
      color: #fff;
    }
    /deep/.el-table .aa .cell {
      display: flex;
      justify-content: center;
    }
  }
</style>
